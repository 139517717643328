import { Alert, Button, Col, Form } from 'react-bootstrap'
import useFetch from '../../../../hooks/useFetch'
import { FormEvent, useEffect, useState } from 'react'
import './ApplicationRetention.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { DateTime } from 'luxon'
import { Popup } from "reactjs-popup"
import { ApplicationRetentionSettings } from './types'


const ApplicationRetention = () => {
  const {data:applicationRetentionSetting=null, refresh} = useFetch<ApplicationRetentionSettings>('/api/application_retention/1');
  const [submitting, setSubmitting] = useState(false);
  const [lockApplicationPurgeSettings, setLockApplicationPurgeSettings] = useState(true);
  const [numberOfMonthsBeforeApplicationPurge, setNumberOfMonthsBeforeApplicationPurge] = useState<number>(0);
  const [numberOfMonthsBeforeApplicationPurgeSave, setNumberOfMonthsBeforeApplicationPurgeSave] = useState<number>(0);
  const [showPopup, setShowPopup] = useState(false);

  let dateOfApplicationPurge = DateTime.now().minus({months: numberOfMonthsBeforeApplicationPurge }).toLocaleString(DateTime.DATE_FULL);

  const submit = (e:FormEvent) => {
    e.preventDefault();
    updateApplicationRetentionSetting();
  }

  const runApplicationRetention = () => {
    setSubmitting(true);
    setShowPopup(true);

    // Runs the deletion of applications
    fetch('/api/application_retention/1/run', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(response => {
      if (!response.ok) {
      throw new Error('Failed to run application retention');
      }
    })
    .catch(error => {
      alert('Error running application retention ' + error.message);
    });
  }

  const toggleLockApplicationPurgeSettings = () => {
    if (applicationRetentionSetting) setNumberOfMonthsBeforeApplicationPurgeSave(applicationRetentionSetting.monthsToRetain);
    setLockApplicationPurgeSettings(!lockApplicationPurgeSettings);
  }

  const updateApplicationRetentionSetting = () => {
    fetch('/api/application_retention/1', {
      method: 'PUT',
      headers: {
      'Content-Type': 'application/json'
      },
      body: JSON.stringify({monthsToRetain: numberOfMonthsBeforeApplicationPurgeSave})
    })
    .then(response => {
      if (!response.ok) {
      throw new Error('Failed to update application retention settings');
      }
      return response.json();
    });

    refresh();
    setSubmitting(false);
    setLockApplicationPurgeSettings(true);
    setShowPopup(false);
  }

  useEffect(() => {
    if (applicationRetentionSetting) {
      setNumberOfMonthsBeforeApplicationPurge(applicationRetentionSetting.monthsToRetain);
      setNumberOfMonthsBeforeApplicationPurgeSave(applicationRetentionSetting.monthsToRetain);
    }
  }, [applicationRetentionSetting]);

  return (
    <>
      <Popup
        open={showPopup}
        position="right center"
        closeOnDocumentClick
        onClose={() => setShowPopup(false)}
      >
        <div className="popup">
          <h2 className="text-danger">
            Are you sure?
          </h2>
          <p>
            You are about to remove all applications that have been submitted 
            before {DateTime.now().minus({months: numberOfMonthsBeforeApplicationPurgeSave }).toLocaleString(DateTime.DATE_FULL)}
          </p>

          <div className="popup-btns">
            <Button
              variant="light"
              onClick={()=>{setShowPopup(false); setSubmitting(false)}}
            >
              Cancel
            </Button>

            <Button
              onClick={() => updateApplicationRetentionSetting()}
              className="text-capitalize"
            >
              Delete
            </Button>
          </div>
        </div>
      </Popup>

      <Alert variant="danger">
        <strong>
          Applications before {dateOfApplicationPurge} will be purged and removed from the database.
        </strong>
        <br/>
        As per our data disposal policies, we can only keep records for CLEAR Applications for {numberOfMonthsBeforeApplicationPurge} months.
      </Alert>
      <Form onSubmit={submit}>
        <Form.Group>
          <Form.Label><strong>Adjust number of months to retain Application Data</strong></Form.Label>
          <Form.Row>
            <Col className="app-retention-months-input-label" sm={1}>
              <Form.Label>Every</Form.Label>
            </Col>
            <Col sm={2}>
              <Form.Control required
                disabled={lockApplicationPurgeSettings}
                onChange={e=>setNumberOfMonthsBeforeApplicationPurgeSave(parseInt(e.target.value))}
                value={numberOfMonthsBeforeApplicationPurgeSave}
                type='number'
                placeholder='# of months'
              />
            </Col>
            <Col className="app-retention-months-input-label" sm={1}>
              <Form.Label>Months</Form.Label>
            </Col>
            <Col sm={5}>
              {
                lockApplicationPurgeSettings ? (
                  <Button variant="outline-primary" disabled={submitting} onClick={toggleLockApplicationPurgeSettings}>
                    <FontAwesomeIcon className='app-retention-month-lock' icon={faLock} />
                    Edit Number of Months
                  </Button>
                ) : null
              }
            </Col>
          </Form.Row>
          <Form.Text className="text-muted">
            Applications will be removed from the system after this many months.
          </Form.Text>
          {
            lockApplicationPurgeSettings ? (
              <>
                <br/>
                <Form.Row>
                  <Col sm={3}>
                    <Button variant="danger" disabled={submitting} onClick={() => runApplicationRetention()}>
                      Delete now
                    </Button>
                  </Col>
                </Form.Row>
              </>
            ) : null
          }
        </Form.Group>
        {
          !lockApplicationPurgeSettings ? (
            <>
              <Form.Row>
                <Col sm={1.5}>
                  <Button variant="light" disabled={submitting} onClick={() => toggleLockApplicationPurgeSettings()}>
                    Cancel
                  </Button>
                </Col>
                <Col sm={1}>
                  <Button variant="primary" disabled={submitting || numberOfMonthsBeforeApplicationPurge === numberOfMonthsBeforeApplicationPurgeSave} type="submit">
                    Save
                  </Button>
                </Col>
            
              </Form.Row>
            </>
          ) : null
        }
      </Form>
    </>
  )
}

export default ApplicationRetention