import { NavLink, Redirect, Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import ApplicationWindow from "./panes/ApplicationWindow"
import PageHeader from "../PageHeader"
import ManageUsers from './panes/ManageUsers/ManageUsers.jsx'
import EmailReminders from "./panes/EmailReminders/EmailReminders"
import PermissionLevel from "../../data/PermissionLevel"
import Unauthorized from "../Unauthorized"
import './AdminSettings.css'
import ApplicationRetention from "./panes/ApplicationRetention"

type Props = {
  currentUser: { Role: { permission_level: number } }
  loginAsUser: (id: number)=>void
}
const AdminDashboard = ({currentUser, loginAsUser}:Props) => {
  const { path } = useRouteMatch()
  const params = useParams()
  
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subRoutes:{[route:string]:[string, any]} = {
    '/application-window': ['Application Window', ApplicationWindow],
    '/manage-users': ['Manage Users', ManageUsers],
    '/email-reminders': ['Email Reminders', EmailReminders],
    '/application-retention': ['Application Retention', ApplicationRetention],
  }
  const defaultRoute = '/application-window'

  if (currentUser.Role.permission_level < PermissionLevel.Officer) {
    return <Unauthorized />
  }

  return (
    <div>
      <PageHeader title="Admin Settings" />

      <div className='admin-container'>
        <div className='admin-menu'>
          {Object.entries(subRoutes).map(([subRoute, [label, _]])=>(
            <NavLink className='btn-primary' key={subRoute} to={(`${path}${subRoute}`)}>{label}</NavLink>
          ))}
        </div>
        <div>
          <Switch>
            {Object.entries(subRoutes).map(([subRoute, [label, AdminPanel]])=>(
              <Route key={subRoute} path={`${path}${subRoute}`}>
                <div className="admin-panel">
                  <h2>{label}</h2>
                  <hr/>
                  <AdminPanel params={params} currentUser={currentUser} loginAsUser={loginAsUser}/>
                </div>
              </Route>
            ))}
            <Route path={path}><Redirect to={`${path}${defaultRoute}`}/></Route>
          </Switch>
        </div>
      </div>
    </div>
  )
}
export default AdminDashboard