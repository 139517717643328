export async function getProjectFairDetails(year) {
  return await fetch(`/api/project_fair/${year}`)
    .then((resp) => resp.json())
    .then((data) => {
      // Filter out projects where active is false
      data.ApprovedProjects = data.ApprovedProjects.filter((project) => project.active !== false);
      data.ApprovedProjects.sort(() => Math.random() - 0.5);
      return data;
    });
}

export async function getPastProjectFairs() {
  return await fetch(`/api/project_fair`).then((resp) =>
    resp.json().then((data) => {
      return data
        .flatMap((a) => (a.active === false ? a.ApprovedProjects : []))
        .sort(() => Math.random() - 0.5);
    })
  );
}
